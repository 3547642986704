import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { WpPage } from '../../../types/wordpress.types';
import joinClassNames from '../../../utils/className.utils';
import { sanitize } from '../../../utils/sanitize.utils';
import BaseLink from '../../BaseLink/BaseLink';
import PageSectionContentOnImage from '../../PageSectionContentOnImage/PageSectionContentOnImage';
import PageSectionHeader from '../../PageSectionHeader/PageSectionHeader';
import './PageSectionContentFromPages.scss';

const ContentToIncludeByHeading = ['cast', 'crew', 'studios']

type PageSectionContentFromPagesProps = {
}

const PageSectionContentFromPages: React.FC<PageSectionContentFromPagesProps> = props => {

  const { pages } = useStaticQuery(graphql`
    {
      pages: allWpPage(filter: {slug: {eq: "filming-in-ireland"}}) {
        nodes {
          ...PageWithContentFragment
        }
      }
    }
  `);

  const pagesQueried: WpPage[] = pages.nodes;

  return <section className="page-section PageSectionContentFromPages">

    <main className="PageSectionContentFromPages__Main">
      {pagesQueried.map((page, pageIdx) => {
        const pageUrl = `/${page.slug}`;
        const { contentPage: content } = page;
        return <div key={pageIdx}>
          <div className="container">
            <PageSectionHeader
              className="PageSectionPageContent__Header"
              text={content.headerHeading}
              buttonConfig={{
                href: `/${page.slug}`,
                icon: 'arrow',
                iconSize: '1em',
                Label: "Learn More",
              }}
            />
            <div className="PageSectionPageContent__MainFirstContentSection">
              <span className="PageSectionPageContent__MainFirstContentSpan" dangerouslySetInnerHTML={{ __html: sanitize(page.contentPage.mainFirstContentSection) }} />
              <div className="PageSectionPageContent__ReadMoreActionLink">
                <BaseLink to={pageUrl}>READ MORE</BaseLink>
              </div>
            </div>
          </div>
          {content.mainContentAlternatingView.map((c, contentIdx) => {
            const alignment = ((pageIdx + 1) * contentIdx) % 2 === 0 ? 'right' : 'left';
            const headingLower = c.heading.toLowerCase();
            if (ContentToIncludeByHeading.includes(headingLower)) {
              return <PageSectionContentOnImage
                id={`ContentFromPagesSection-FrontPageContentSection-${contentIdx}`}
                key={contentIdx}
                className={joinClassNames("FrontPageContentSection", headingLower)}
                contentPosition={alignment}
                image={{
                  media: c.featuredImage,
                  altText: c.featuredImage?.altText,
                }}
                content={{
                  heading: c.heading,
                  body: c.body,
                  // doNotIncludeRelatedMediaSpacer: true,
                  relatedSubproduct: c.relatedSubproduct,
                }}
              />
            }
            return null;
          })}

        </div>
      })}
    </main>

  </section>
}

export default PageSectionContentFromPages;