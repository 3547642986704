// import React from 'react';

import { Nillable, Renderable } from '../../types/base.types';
import { WpLink, WpMedia, WpProduct } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import { sanitizeAllowExtra } from '../../utils/sanitize.utils';
import BaseButton from '../BaseButton/BaseButton';
import BaseImage from '../BaseImage/BaseImage';
import BaseLink from '../BaseLink/BaseLink';
import BaseSpacer from '../BaseSpacer/BaseSpacer';
import HorizontalSwiper from '../HorizontalSwiper/HorizontalSwiper';
import ProductCard from '../ProductCard/ProductCard';
import PageSection from '../PageSection/PageSection';
import './PageSectionContentOnImage.scss';

import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';
import React from 'react';

import "swiper/css";
import "swiper/css/lazy";
import { Autoplay } from 'swiper';
import { useStore } from '../../utils/mobx.utils';
import { useOnMount } from '../../hooks/lifecycle.hooks';
import { action } from 'mobx';

type PageSectionContentOnImageProps = { id: string } & Partial<{
  className: string,
  contentPosition: 'left' | 'right',
  image: {
    media: Nillable<WpMedia>,
    altText: Nillable<string>,
  },
  imageCaption: {
    Icon: Renderable,
    caption: Nillable<string>,
  },
  content: Partial<{
    Icon: Renderable,
    heading: string,
    subheading: string,
    HeaderAndBodySeparator: Renderable,
    body: string,
    doNotIncludeRelatedMediaSpacer: boolean,
    relatedMedia: Nillable<(WpMedia & { actionLink?: WpLink })[]>,
    relatedMediaAsActionLink: boolean,
    relatedMediaObjectFit: 'cover' | 'contain',
    relatedSubproduct: Nillable<WpProduct[]>,
    actionLink: Nillable<WpLink>,

    NonConfigurableContent: Renderable,
  }>
  isSwiper?: boolean,
}>

const PageSectionContentOnImage: React.FC<PageSectionContentOnImageProps> = props => {
  const p = props;

  const s = useStore(() => ({
    _sizeLgStr: "" as string,
    get sizeLg() { return (parseFloat(s._sizeLgStr) || 5.2) * 10; },
    get swiperConfig() {
      return {
        // speed: 15000,
        // speed: 6000,
        spaceBetween: s.sizeLg * 1.5,
        loop: true,
        // noSwiping: true,
        // freeMode: {
        //   enabled: true,
        //   momentumVelocityRatio: 1,
        // },
        // lazy: true,
        slidesPerView: 2,
        autoplay: {
          // delay: 0,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            // speed: 25000,
            slidesPerView: 3,
          },
          1920: {
            // speed: 27500,
            slidesPerView: 4,
          }
        },
        // modules: [Lazy, Autoplay],
        modules: [Autoplay],
      }
    }
  }))
  useOnMount(() => {
    action(() => {
      s._sizeLgStr = window.getComputedStyle(document.body).getPropertyValue('--size-lg') as string;
    })()
  })
  return <PageSection
    id={joinClassNames('PageSectionContentOnImage', p.id)}
    className={joinClassNames("PageSectionContentOnImage page-section", p.className)}
    dataContentAlignment={p.contentPosition}
    observeVisibility
  >
    <div className="PageSectionContentOnImageContainer container">

      <figure className="ContentOnImage__ImageContainer">
        <picture className="ContentOnImage__ImageInner">
          {p.image?.media && <BaseImage
            className="ContentOnImage__BaseImage"
            media={p.image.media}
            altText={p.image.altText ?? ""}
            imageType='gatsbyDynamic'
            animateLoad
          />}
        </picture>
        {p.imageCaption && <figcaption className="ContentOnImage__ImageCaption">
          {p.imageCaption.Icon}
          <span>{p.imageCaption.caption}</span>
        </figcaption>}
      </figure>

      <article className="ContentOnImage__Content">
        <div className="ContentOnImage__ContentInner">

          <div className="ContentOnImage__ContentInnerTop">
            {props.content?.Icon}

            {p.content?.heading && <h2 className="ContentOnImage__ContentHeading">{p.content.heading}</h2>}

            {p.content?.subheading && <p className="ContentOnImage__ContentSubheading">{p.content.subheading}</p>}

            {(p.content?.heading || p.content?.subheading) && props.content?.HeaderAndBodySeparator}

            {p.content?.body && <div className="ContentOnImage__ContentBody" dangerouslySetInnerHTML={{ __html: sanitizeAllowExtra(p.content.body ?? "", ['img'], { allowedAttributes: { 'div': ['class'], 'a': ['href'] }, allowedClasses: { 'div': ['Lists', 'ContentList'] } }) }} />}
          </div>

          {p.content?.relatedMedia && <>
            {!p.content?.doNotIncludeRelatedMediaSpacer && <BaseSpacer size='3em' />}
            {/* If isSwiper == true */}
            {p.isSwiper ? 
              <ReactSwiper {...s.swiperConfig} className="ContentOnImage-swiper" >
                {p.content.relatedMedia.map((media, idx) => {
                const Image = <BaseImage
                  // key={`${media.id}_${idx}`}
                  className="ContentOnImage__ContentRelatedMedia"
                  media={media}
                  altText={media.altText}
                  imageType='gatsbyDynamic'
                  objectFit={p.content?.relatedMediaObjectFit}
                />;
                if (p.content?.relatedMediaAsActionLink && media.actionLink?.url) return <SwiperSlide className="" key={`${media.id}_${idx}`}><BaseLink key={media.id} to={media.actionLink.url} externalInNewTab>
                  {Image}
                </BaseLink></SwiperSlide>;

                return <SwiperSlide className="ContentOnImage-swiper" key={`${media.id}_${idx}`}>{Image}</SwiperSlide>
              })}
              </ReactSwiper>
             :
             <HorizontalSwiper>
              {p.content.relatedMedia.map((media, idx) => {
                const Image = <BaseImage
                  key={`${media.id}_${idx}`}
                  className="ContentOnImage__ContentRelatedMedia"
                  media={media}
                  altText={media.altText}
                  imageType='gatsbyDynamic'
                  objectFit={p.content?.relatedMediaObjectFit}
                />;
                if (p.content?.relatedMediaAsActionLink && media.actionLink?.url) return <BaseLink key={media.id} to={media.actionLink.url} externalInNewTab>
                  {Image}
                </BaseLink>;
                return Image;
              })}
            </HorizontalSwiper>
            }
          </>}

          {p.content?.relatedSubproduct && <>
            <BaseSpacer size='1em' />
            <HorizontalSwiper>
              {p.content.relatedSubproduct.map(product => <ProductCard
                className="ContentOnImage__ContentRelatedSubproduct"
                key={product.id}
                product={product}
                canViewDetails={true}
              />)}
            </HorizontalSwiper>
          </>}

          <BaseSpacer size='0.5em' />

          {p.content?.actionLink && <BaseLink className="ContentOnImage__ContentActionLink" to={p.content?.actionLink.url} title={p.content?.actionLink.title}><BaseButton>{p.content?.actionLink.title}</BaseButton></BaseLink>}

          {p.content?.NonConfigurableContent}

        </div>
      </article>

    </div>
  </PageSection>
}

export default PageSectionContentOnImage;